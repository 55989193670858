<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="80vw"
    content-class="dialog"
  >
    <v-card class="overflow-hidden">
      <v-app-bar
        absolute
        color="primary"
      >
        <v-spacer></v-spacer>
        <div
          v-if="currentIndex !== null && totalItems > 0"
          style="user-select: none;"
        >
          <v-btn
            icon
            :disabled="currentIndex == 0 || isChangingTask"
            @click="changeTask(-1)"
          >
            <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
          </v-btn>
          Aufgabe {{ index + 1 }} von {{ totalItems }}
          <v-btn
            icon
            :disabled="currentIndex >= (totalItems - 1) || isChangingTask"
            @click="changeTask(1)"
          >
            <v-icon>{{ icons.mdiChevronRight }}</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="closeTask"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-app-bar>
      <div style="height: 64px;"></div>
      <v-sheet
        style="position: static; top: 64px; overflow-y: scroll !important;"
        class="goetel-scrollbar"
        max-height="90vh"
      >
        <task-content
          class="task-content pa-2"
          :data="task.taskDto"
          @onClose="closeTask"
          @onUpdateEntry="$emit('updateTaskData', $event)"
          @refresh="$emit('refreshTaskData')"
        ></task-content>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>
<script>
import TaskContent from '@/task/TaskContent';
import { mdiChevronLeft, mdiChevronRight, mdiClose } from '@mdi/js';

export default {
  name: 'TaskDialog',
  components: {
    TaskContent
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    currentIndex: {
      type: Number,
      required: false,
      default: null
    },
    totalItems: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiClose
      },
      isChangingTask: false,
      preservedIndex: null,
      showDialog: true
    };
  },
  computed: {
    index: function () {
      return this.isChangingTask ? this.preservedIndex : this.currentIndex;
    }
  },
  methods: {
    closeTask: function () {
      this.$emit('closeTask');
    },
    changeTask: function (offset) {
      this.isChangingTask = true;
      this.preservedIndex = this.currentIndex;
      this.$emit('loadTaskIndex', this.currentIndex + offset);
      const oldIndex = this.currentIndex;
      const interval = setInterval(() => {
        if (oldIndex !== this.currentIndex) {
          clearInterval(interval);
          this.isChangingTask = false;
        }
      }, 200);
    }
  }
};
</script>
<style>
.dialog {
  align-self: flex-start;
}

.dialog-sheet {
  overflow-y: scroll !important;
}

.v-dialog {
  max-height: unset !important;
}

.task-content {
  background-color: var(--goe-background-highlight);
  overflow: auto;
}

.displayBodyMainComponent {
  font-size: 0.9rem !important;
}

.theme--dark.task-content {
  background-color: #ffffff;
}

/* Firefox */
.goetel-scrollbar,
.goetel-scrollbar .v-data-table__wrapper {
  scrollbar-width: auto;
  scrollbar-color: var(--goe-borderColor-dark) #ffffff;
  overflow-y: auto;
  overflow-x: auto;
}

.theme--dark.goetel-scrollbar,
.theme--dark.goetel-scrollbar .v-data-table__wrapper {
  scrollbar-color: var(--goe-fontColor-highlightDark)
    var(--goe-background-highlight);
}

/* Chrome, Edge, and Safari */
.goetel-scrollbar::-webkit-scrollbar,
.goetel-scrollbar .v-data-table__wrapper::-webkit-scrollbar {
  width: 16px;
}

.goetel-scrollbar::-webkit-scrollbar-track,
.goetel-scrollbar .v-data-table__wrapper::-webkit-scrollbar-track {
  background: var(--goe-background-highlight);
}

.goetel-scrollbar::-webkit-scrollbar-thumb,
.goetel-scrollbar .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--goe-fontColor-highlightDark);
  border-radius: 10px;
  border: 3px solid #ffffff;
}
</style>
